import React, { useCallback, useRef, useState } from 'react';
import InputButton from "../../../../../../../../theme/component/input-button";
import style from './image-cropper.module.scss';
import { ButtonColor } from "../../../../../../../../theme/component/input-button/models/button-color.model";
import ScaleIcon from '../../../../../../../../theme/resource/reactIcons/scale';

interface ImageCropperProps {
    imageUrl: string;
    imageType?: 'profile' | 'banner';
    onCrop: (
        croppedImage: File,
        cropData: {
            x: number;     // top-left X in natural coordinates
            y: number;     // top-left Y in natural coordinates
            width: number; // width in natural coordinates
            height: number;// height in natural coordinates
        }
    ) => void;
    onCancel: () => void;
}

const ImageCropper: React.FC<ImageCropperProps> = ({
                                                       imageUrl,
                                                       imageType,
                                                       onCrop,
                                                       onCancel
                                                   }) => {
    const [imgRect, setImgRect] = useState({ x: 0, y: 0, width: 0, height: 0 });
    const [cropBox, setCropBox] = useState({ x: 0, y: 0, width: 100, height: 100 });

    const containerRef = useRef<HTMLDivElement>(null);
    const imageRef = useRef<HTMLImageElement>(null);

    // For banner => 4:1, for profile => 1:1
    const aspectRatio = imageType === 'banner' ? 4 : 1;

    // Once the image is loaded, measure how it's displayed.
    const handleImageLoad = useCallback(() => {
        if (!imageRef.current || !containerRef.current) return;

        const containerBox = containerRef.current.getBoundingClientRect();
        const imgBox = imageRef.current.getBoundingClientRect();

        // Determine where the image sits within the container
        const offsetX = imgBox.left - containerBox.left;
        const offsetY = imgBox.top - containerBox.top;
        const w = imgBox.width;
        const h = imgBox.height;

        setImgRect({ x: offsetX, y: offsetY, width: w, height: h });

        // Set an initial crop box near the center
        if (imageType === 'banner') {
            // For a 4:1 ratio => width = 4 × height
            const initW = w * 0.8;
            const initH = initW / aspectRatio; // initW / 4
            setCropBox({
                x: (w - initW) / 2,
                y: (h - initH) / 2,
                width: initW,
                height: initH
            });
        } else {
            // For profile => circle => 1:1
            const side = Math.min(w, h) * 0.7;
            setCropBox({
                x: (w - side) / 2,
                y: (h - side) / 2,
                width: side,
                height: side
            });
        }
    }, [imageType, aspectRatio]);

    // ---------------------------
    // Move the crop box
    // ---------------------------
    const onMoveStart = useCallback(
        (startEvent: React.MouseEvent | React.TouchEvent) => {
            startEvent.preventDefault();

            const startX = cropBox.x;
            const startY = cropBox.y;

            let clientStartX: number, clientStartY: number;
            if ('touches' in startEvent) {
                clientStartX = startEvent.touches[0].clientX;
                clientStartY = startEvent.touches[0].clientY;
            } else {
                clientStartX = startEvent.clientX;
                clientStartY = startEvent.clientY;
            }

            const onMove = (moveEvent: MouseEvent | TouchEvent) => {
                let clientX: number, clientY: number;
                if ('touches' in moveEvent) {
                    clientX = moveEvent.touches[0].clientX;
                    clientY = moveEvent.touches[0].clientY;
                } else {
                    clientX = moveEvent.clientX;
                    clientY = moveEvent.clientY;
                }

                const dx = clientX - clientStartX;
                const dy = clientY - clientStartY;

                let newX = startX + dx;
                let newY = startY + dy;

                // Clamp inside displayed image
                newX = Math.max(0, Math.min(newX, imgRect.width - cropBox.width));
                newY = Math.max(0, Math.min(newY, imgRect.height - cropBox.height));

                setCropBox(prev => ({ ...prev, x: newX, y: newY }));
            };

            const onEnd = () => {
                document.removeEventListener('mousemove', onMove);
                document.removeEventListener('mouseup', onEnd);
                document.removeEventListener('touchmove', onMove);
                document.removeEventListener('touchend', onEnd);
            };

            document.addEventListener('mousemove', onMove);
            document.addEventListener('mouseup', onEnd);
            document.addEventListener('touchmove', onMove, { passive: false });
            document.addEventListener('touchend', onEnd);
        },
        [cropBox, imgRect]
    );

    // ---------------------------
    // Resize the crop box
    // ---------------------------
    const onResizeStart = useCallback(
        (startEvent: React.MouseEvent | React.TouchEvent) => {
            startEvent.preventDefault();
            startEvent.stopPropagation();

            const startW = cropBox.width;
            const startH = cropBox.height;
            const startX = cropBox.x;
            const startY = cropBox.y;

            let clientStartX: number, clientStartY: number;
            if ('touches' in startEvent) {
                clientStartX = startEvent.touches[0].clientX;
                clientStartY = startEvent.touches[0].clientY;
            } else {
                clientStartX = startEvent.clientX;
                clientStartY = startEvent.clientY;
            }

            const onResize = (moveEvent: MouseEvent | TouchEvent) => {
                let clientX: number, clientY: number;
                if ('touches' in moveEvent) {
                    clientX = moveEvent.touches[0].clientX;
                    clientY = moveEvent.touches[0].clientY;
                } else {
                    clientX = moveEvent.clientX;
                    clientY = moveEvent.clientY;
                }

                const dx = clientX - clientStartX;
                const dy = clientY - clientStartY;
                const biggerDiff = Math.max(dx, dy);

                let newW = startW + biggerDiff;
                let newH = imageType === 'banner'
                    ? newW / aspectRatio
                    : newW; // profile => square

                // clamp so we don't exceed displayed image
                if (startX + newW > imgRect.width) {
                    newW = imgRect.width - startX;
                    newH = imageType === 'banner'
                        ? newW / aspectRatio
                        : newW;
                }
                if (startY + newH > imgRect.height) {
                    newH = imgRect.height - startY;
                    newW = imageType === 'banner'
                        ? newH * aspectRatio
                        : newH;
                }

                // min size
                const MIN_SIZE = 30;
                if (newW < MIN_SIZE || newH < MIN_SIZE) return;

                setCropBox(prev => ({ ...prev, width: newW, height: newH }));
            };

            const onEnd = () => {
                document.removeEventListener('mousemove', onResize);
                document.removeEventListener('mouseup', onEnd);
                document.removeEventListener('touchmove', onResize);
                document.removeEventListener('touchend', onEnd);
            };

            document.addEventListener('mousemove', onResize);
            document.addEventListener('mouseup', onEnd);
            document.addEventListener('touchmove', onResize, { passive: false });
            document.addEventListener('touchend', onEnd);
        },
        [cropBox, imgRect, aspectRatio, imageType]
    );

    // Circle => handle at 45°, banner => bottom-right
    const getResizeHandleStyle = () => {
        if (imageType === 'profile') {
            const radius = cropBox.width / 2;
            const angle = Math.PI / 4;
            return {
                left: `${radius + radius * Math.cos(angle) - 10}px`,
                top:  `${radius + radius * Math.sin(angle) - 10}px`,
                cursor: 'nwse-resize'
            };
        }
        return {
            right: '-10px',
            bottom: '-10px',
            cursor: 'se-resize'
        };
    };

    // perform final crop
    const handleCrop = () => {
        if (!imageRef.current) return;

        const image = imageRef.current;
        const scaleX = image.naturalWidth / imgRect.width;
        const scaleY = image.naturalHeight / imgRect.height;

        // round to avoid subpixel differences
        const sx = Math.round(cropBox.x * scaleX);
        const sy = Math.round(cropBox.y * scaleY);
        const sw = Math.round(cropBox.width * scaleX);
        const sh = Math.round(cropBox.height * scaleY);

        const canvas = document.createElement('canvas');
        const ctx = canvas.getContext('2d');
        if (!ctx) return;

        canvas.width = sw;
        canvas.height = sh;
        ctx.drawImage(image, sx, sy, sw, sh, 0, 0, sw, sh);

        canvas.toBlob(blob => {
            if (!blob) return;
            const file = new File([blob], 'cropped_image.jpg', { type: 'image/jpeg' });
            onCrop(file, { x: sx, y: sy, width: sw, height: sh });
        }, 'image/jpeg');
    };

    return (
        <div className={style.modalOverlay}>
            <div className={style.modal}>
                <h2 className={style.modalTitle}>
                    Crop {imageType === 'profile' ? 'Profile Picture' : 'Banner'}
                </h2>

                <div
                    ref={containerRef}
                    style={{
                        boxSizing: 'content-box',
                        width: '80vw',
                        height: '80vh',
                        position: 'relative',
                        overflow: 'hidden',
                        margin: 0,
                        padding: 0,
                        background: '#f0f0f0'
                    }}
                >
                    <img
                        ref={imageRef}
                        src={imageUrl}
                        alt="Crop"
                        onLoad={handleImageLoad}
                        style={{
                            boxSizing: 'content-box',
                            width: '100%',
                            height: '100%',
                            objectFit: 'contain',
                            display: 'block',
                            margin: 0,
                            padding: 0
                        }}
                    />

                    {imgRect.width > 0 && (
                        <div
                            style={{
                                position: 'absolute',
                                left: imgRect.x,
                                top: imgRect.y,
                                width: imgRect.width,
                                height: imgRect.height,
                                margin: 0,
                                padding: 0
                            }}
                        >
                            <div
                                style={{
                                    position: 'absolute',
                                    left: cropBox.x,
                                    top: cropBox.y,
                                    width: cropBox.width,
                                    height: cropBox.height,
                                    borderRadius: imageType === 'profile' ? '50%' : '0',
                                    border: '2px solid #fff',
                                    boxShadow: '0 0 0 9999px rgba(0,0,0,0.5)',
                                    cursor: 'move'
                                }}
                                onMouseDown={onMoveStart}
                                onTouchStart={onMoveStart}
                            >
                                <div
                                    style={{
                                        ...getResizeHandleStyle(),
                                        position: 'absolute',
                                        width: 20,
                                        height: 20,
                                        borderRadius: '50%',
                                        backgroundColor: '#fff',
                                        boxShadow: '0 0 6px rgba(0,0,0,0.5)',
                                        display: 'flex',
                                        alignItems: 'center',
                                        justifyContent: 'center'
                                    }}
                                    onMouseDown={onResizeStart}
                                    onTouchStart={onResizeStart}
                                >
                                    <ScaleIcon size={16} />
                                </div>
                            </div>
                        </div>
                    )}
                </div>

                <div className={style.modalFooter}>
                    <InputButton label="Cancel" color={ButtonColor.Failure} onClick={onCancel} />
                    <InputButton label="Upload Image" onClick={handleCrop} />
                </div>
            </div>
        </div>
    );
};

export default ImageCropper;
