import React, { useEffect, useState } from 'react'
import { Link, useNavigate } from 'react-router-dom'

import style from './index.module.scss'
import { useTranslation } from 'react-i18next'
import useQuery from "../../../theme/hooks/use-query.component";
import InlineToast from "../../../theme/component/inline-notification";
import sessionService from "../../../service/session/session.service";
import {InlineToastType} from "../../../theme/component/inline-notification/model/inline-notification-type.model";
import InputText from "../../../theme/component/input-text/input-text.component";
import InputButton from "../../../theme/component/input-button";
import {
    ThirdPartyAuthorizationService
} from "../../../service/third-party-authorization/third-party-authorization.service";
import SessionDetails from "../../../service/session/model/session-details";
import {AccountService} from "../../../service/account/account.service";
import GoogleIcon from "../../icon/google-icon";
import {Capacitor} from "@capacitor/core";
import {AppleLoginService} from "../../../service/apple-login/apple-login.service";
import AppleIcon from "../../icon/apple-icon";

export default function HeroLogin() {
    const { t } = useTranslation()
    const urlExtractedQuery = useQuery('return_to', '/explore')
    const navigate = useNavigate()

    const [login, setLogin] = useState<string>('')
    const [password, setPassword] = useState<string>('')
    const [disableLogin, setDisableLogin] = useState<boolean>(false)

    const [showToast, setShowToast] = useState<boolean>(false)
    const [toastMessage, setToastMessage] = useState<string>('')
    const [toastType, setToastType] = useState<InlineToastType>(null)

    useEffect(() => {
        if (sessionService.getSessionDetails().authenticated)
            navigate(urlExtractedQuery)
    }, [])

    return <div className={style.container}>

        <div className={style.input_area}>
            <div className='text-center max-width'>
                <div className='mb2 mt1'>

                    <InlineToast show={showToast} message={toastMessage} type={toastType} />
                </div>
                <div className='mb2'>
                    <InputText placeholder={t('login.mail_or_username')} onChange={setLogin} />
                </div>
                <div className='mb3'>
                    <InputText placeholder={t('login.password')} isPassword={true} onChange={setPassword} onEnter={loginHandler} />
                </div>
                <div className='mb2'>
                    <InputButton label={t('login.sign_in')} fill={true} onClick={loginHandler} disabled={disableLogin} />
                </div>
                <div className='mb3'>
                    <div className={`${style.or} mb1`}>
                        <span className={style.label}>{t('login.or')}</span>
                    </div>
                    <div className={`${style.google_authentication_button} mb2`} onClick={() => thirdPartyLogin('google')}>
                        <span className={style.icon}>
                            <GoogleIcon />
                        </span>
                        <span className={style.label}>{t('login.sign_in_google')}</span>
                    </div>
                    <div className={`${style.apple_authentication_button} mb2`} onClick={() => thirdPartyLogin('apple')}>
                        <span className={style.icon}>
                            <AppleIcon />
                        </span>
                        <span className={style.label}>{t('login.sign_in_apple')}</span>
                    </div>
                </div>
                <div className='mb2'>
                    <Link to='/forgot-password'>{t('login.forgot_password')}</Link>
                </div>
            </div>

        </div>


    </div>

    async function thirdPartyLogin(provider: string) {
        if (Capacitor.getPlatform() === 'ios') {
            await AppleLoginService.login()
            return
        }

        const url = await ThirdPartyAuthorizationService.getAuthorizeEndpoint(provider)

        console.log(url)
        window.location.href = url
    }

    function showBadLogin() {
        setShowToast(true)
        setToastMessage(t('login.bad_login'))
        setToastType(InlineToastType.Failure)
    }

    async function loginHandler() {
        setDisableLogin(true)

        const session: SessionDetails = await AccountService.signIn(login, password)

        if (session?.authenticated === true) {
            navigate(urlExtractedQuery)
            return
        }

        showBadLogin()
        setDisableLogin(false)
    }
}
